import * as PIXI from 'pixi.js';

import { EventTypes, MessageWinBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { Z_INDEX_BANNER, eventManager } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_Y_LAND,
  MESSAGE_WIN_BANNER_Y_PORT,
} from './config';
import { totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  private isLandScape: boolean;

  protected bindApplicationResize = this.resize.bind(this);
  protected bindApplicationHandleDestroy = this.handleDestroy1.bind(this);

  constructor(props: MessageWinBannerProps, isLandScape: boolean) {
    super(props);
    this.props = props;
    this.zIndex = Z_INDEX_BANNER;

    this.position.x = 463;
    this.position.y = 0;
    this.isLandScape = isLandScape;
    eventManager.emit(EventTypes.OPEN_MESSAGE_BANNER);
    eventManager.addListener(EventTypes.RESIZE, this.bindApplicationResize);
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindApplicationHandleDestroy);
    this.banner?.scale.set(0.69);
  }

  public override init(): MessageWinBanner {
    super.init(ResourceTypes.winBanner);

    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.addChild(this.title, this.totalWin);

    if (this.isLandScape) {
      this.resize(10, 1);
      this.y = MESSAGE_WIN_BANNER_Y_LAND;
    } else {
      this.resize(1, 10);
      this.y = MESSAGE_WIN_BANNER_Y_PORT;
    }

    return this;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.anchor.set(0.5, 0);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    updateTextScale(winTitle, this.banner!.width - 150, 250);

    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.anchor.set(0.5, 0);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    updateTextScale(winTotal, this.banner!.width - 150, 250);

    return winTotal;
  }

  override updateTransform(): void {
    super.updateTransform();
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.y = MESSAGE_WIN_BANNER_Y_LAND;
      this.title!.y = MESSAGE_WIN_BANNER_TITLE_Y;
      this.totalWin!.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    } else {
      this.y = MESSAGE_WIN_BANNER_Y_PORT;
      this.title!.y = MESSAGE_WIN_BANNER_TITLE_Y;
      this.totalWin!.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    }
  }

  private handleDestroy1(): void {
    eventManager.removeListener(EventTypes.RESIZE, this.bindApplicationResize);
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindApplicationHandleDestroy);
    this.destroy({ children: true });
  }
}
